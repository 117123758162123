/**
 * Simple component for grouping the performance,  and related meta tags that are in every layout
 */
export default function Meta(): JSX.Element {
    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link
                rel="preconnect"
                href="https://www.googletagmanager.com"
                crossOrigin="anonymous"
            />
            <script src="https://cmp.osano.com/Azyi0LUXoaiwq3cbY/2148da2f-e63e-448e-a542-eabd0ff9e753/osano.js"></script>
            <link rel="preconnect" href="https://api.rabbits.webcam" crossOrigin="anonymous" />
            <link
                rel="preconnect"
                href="https://www.google-analytics.com"
                crossOrigin="anonymous"
            />
            <link rel="dns-prefetch" href="https://www.googletagmanager.com" crossOrigin="" />
            <link rel="dns-prefetch" href="https://api.rabbits.webcam" crossOrigin="" />
            <link rel="dns-prefetch" href="https://www.google-analytics.com" crossOrigin="" />
        </>
    );
}
